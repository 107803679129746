import styled from 'styled-components'
import { Container } from '../salesAssistant/CommonSalesAssistant'
import { ChangeEvent, useState } from 'react'
import { useProfile } from '@/profile/useProfle'
import { StyledInput, StyledSubtitle } from './CommonComponents'
import { CalculatorInput } from '@/computeCalculator/CommonCalculator'

export const InputWithTextContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;

  .average-electric-bill:hover {
    border: none;
    background-color: var(--base-white);
    outline: solid 1px var(--gray-light-mode-400);
  }
`

const DollarSign = styled.span`
  position: absolute;
  left: 10px;
  color: var(--gray-light-mode-900);
  font-size: var(--font-text-xl);
  font-weight: var(--font-weight-normal);
  line-height: 1.56;
  text-align: left;
`

export const MonthText = styled.span`
  position: absolute;
  right: 20px;
  color: var(--base-black);
  font-size: var(--font-text-xl);
  font-weight: var(--font-weight-medium);
  line-height: 1.56;
  text-align: left;
`

type AverageElectricBillProps = {
  onBillChange: (value: string) => void
  value?: number | string
  isCalculator?: boolean
}

export function AverageElectricBill({ onBillChange, value, isCalculator }: Readonly<AverageElectricBillProps>) {
  const { profile } = useProfile()
  const [averageBill, setAverageBill] = useState<string>(
    profile?.monthly_electric_bill?.amount?.toString() ?? value?.toString() ?? ''
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value === '' || /^[0-9\b]+$/.test(value)) {
      setAverageBill(value)
      onBillChange(value)
    }
  }
  return (
    <Container>
      <StyledSubtitle>Average Electric Bill</StyledSubtitle>
      <InputWithTextContainer>
        {isCalculator ? (
          <CalculatorInput
            className="average-electric-bill"
            type="text"
            placeholder=""
            min={1}
            value={averageBill}
            onChange={handleChange}
          />
        ) : (
          <StyledInput
            className="average-electric-bill"
            type="text"
            placeholder=""
            min={1}
            value={averageBill}
            onChange={handleChange}
          />
        )}
        {averageBill && <DollarSign>$</DollarSign>}
        <MonthText>/ month</MonthText>
      </InputWithTextContainer>
    </Container>
  )
}
