import styled from 'styled-components'

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 36px;
  border: solid 1px var(--gray-modern-300);
  border-radius: var(--radius-xl);
  background-color: var(--base-white);
`

const InputText = styled.span<{ $position?: number }>`
  position: absolute;
  left: ${({ $position }) => `${$position}px`};
  color: var(--gray-modern-900);
  font-size: var(--font-text-xl);
  font-weight: var(--font-weight-medium);
  line-height: 1.5;
`

const Input = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  color: var(--gray-modern-900);
  font-size: var(--font-text-xl);
  font-weight: var(--font-weight-medium);
  line-height: 1.5;
  outline: none;
  text-align: left;
`

type InputNumberProps = React.InputHTMLAttributes<HTMLInputElement> & {
  symbol?: string
  symbolPosition?: number
}

const InputNumber = ({ onChange, symbol, value, symbolPosition, step, ...props }: InputNumberProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value)
    if (newValue < 0) {
      e.target.value = '0'
    }

    onChange?.(e)
  }
  return (
    <InputWrapper>
      <Input step={step} type="number" value={value ?? ''} onChange={handleInputChange} {...props} />
      <InputText $position={symbolPosition}>{symbol}</InputText>
    </InputWrapper>
  )
}

export default InputNumber
