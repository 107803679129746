import styled from 'styled-components'
import avaImg from '@/assets/images/calculator/ava.webp'
import avaImgx2 from '@/assets/images/calculator/ava@2x.webp'
import avaImgx3 from '@/assets/images/calculator/ava@3x.webp'
import Select from './Select'
import { Container } from './CommonCalculator'
import { Option } from '@/uiComponents/CommonComponents'
import InputNumber from './InputNumber'
import { GPUOption } from './Calculator'

const CardContainer = styled.article`
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 24px;
  border: solid 4px var(--gray-modern-100);
  border-radius: 32px;
`
const ImageContainer = styled.div`
  width: 266px;
  height: 270px;
  margin-top: 40px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: var(--radius-4xl);
  object-fit: cover;
`

const AttributesContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
`

const AttributeLabel = styled.span`
  display: flex;
  color: var(--gray-modern-900);
  font-size: var(--font-text-md);
  font-weight: var(--font-weight-bold);
  gap: 10px;
  text-align: left;
`

const AttributeName = styled.p`
  color: var(--gray-modern-900);
  font-size: var(--font-text-md);
  font-weight: var(--font-weight-medium);
  text-align: left;
`

const Wrapper = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 50px;
`

const CardInfoContainer = styled(Container)`
  gap: 32px;
`

const CardTitle = styled.span`
  width: '100%';
  padding-inline: 20px;
  text-align: left;
`

const GPUOptionsContainer = styled.div`
  display: grid;
  align-items: center;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
`

type AvaNodeCardProps = {
  gpuOptions: GPUOption[]
  onGPUChange: (gpuModel: string) => void
  gpuSettings: {
    model: GPUOption
    cardCount: number
    rentProbability: number
    rentCostPerHour: number
  }
  setGpuSettings: React.Dispatch<
    React.SetStateAction<{
      model: GPUOption
      cardCount: number
      rentProbability: number
      rentCostPerHour: number
    }>
  >
}

const AvaNodeCard = ({ onGPUChange, gpuOptions, gpuSettings, setGpuSettings }: AvaNodeCardProps) => {
  const imageSrcSet = `${avaImg} 1x, ${avaImgx2} 2x, ${avaImgx3} 3x`

  const onHandleGPUCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const count = parseInt(e.target.value)
    setGpuSettings((prev) => ({
      ...prev,
      cardCount: isNaN(count) ? 1 : count,
    }))
  }

  const onHandleRentProbabilityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value)
    setGpuSettings((prev) => ({
      ...prev,
      rentProbability: isNaN(value) ? prev.rentProbability : value,
    }))
  }

  const onHandleRentCostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value)
    setGpuSettings((prev) => ({
      ...prev,
      rentCostPerHour: isNaN(value) ? prev.rentCostPerHour : value,
    }))
  }

  return (
    <CardContainer>
      <CardTitle>Ava Node</CardTitle>
      <Wrapper>
        <ImageContainer>
          <Image src={avaImg} srcSet={imageSrcSet} alt="" />
        </ImageContainer>
        <CardInfoContainer>
          <AttributesContainer>
            <Container>
              <AttributeLabel>CPU</AttributeLabel>
              <AttributeName>AMD Ryzen Threadripper</AttributeName>
            </Container>
            <Container>
              <AttributeLabel>RAM</AttributeLabel>
              <AttributeName>16 Gb</AttributeName>
            </Container>
            <Container>
              <AttributeLabel>SSD Hard Drive</AttributeLabel>
              <AttributeName>1 TB</AttributeName>
            </Container>
            <Container>
              <AttributeLabel>Motherboard</AttributeLabel>
              <AttributeName>Gigabyte</AttributeName>
            </Container>
          </AttributesContainer>
          <GPUOptionsContainer>
            <Select
              title="GPU Model"
              onChange={(gpu) => {
                onGPUChange(gpu)
              }}
              width="250"
            >
              {gpuOptions.map((gpu: GPUOption) => (
                <Option key={gpu.gpu_model_name} value={gpu.gpu_model_name}>
                  {gpu.gpu_model_name}
                </Option>
              ))}
            </Select>
            <Container width="250">
              <AttributeName>Number of GPU Cards</AttributeName>
              <InputNumber value={gpuSettings.cardCount} onChange={onHandleGPUCount} />
            </Container>
            <Container width="250">
              <AttributeName>Monthly Load</AttributeName>
              <InputNumber
                value={gpuSettings.rentProbability}
                onChange={onHandleRentProbabilityChange}
                symbol="%"
                symbolPosition={65}
              />
            </Container>
            <Container width="250">
              <AttributeName>Hourly Rate</AttributeName>
              <InputNumber
                value={gpuSettings.rentCostPerHour}
                onChange={onHandleRentCostChange}
                symbol="$"
                symbolPosition={25}
              />
            </Container>
          </GPUOptionsContainer>
        </CardInfoContainer>
      </Wrapper>
    </CardContainer>
  )
}

export default AvaNodeCard
