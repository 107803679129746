import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import ProfitSources from './ProfitSources'
import EstimatedSystem from './EstimatedSystem'
import { Container, OptionTitle } from './CommonCalculator'
import { Button } from '@/uiComponents/button/Button'
import { useGoogleMapsLoader } from '@/useGoogleMapsLoader'
import { useProfile } from '@/profile/useProfle'
import { AddresInput } from '@/uiComponents/AddressInput'
import { Oval } from 'react-loader-spinner'
import { AverageElectricBill } from '@/uiComponents/AverageElectricBill'
import { StyledSubtitle } from '@/uiComponents/CommonComponents'
import InputNumber from './InputNumber'
import ToggleOption from './ToggleOption'
import Counter from '@/uiComponents/Counter'
import AvaNodeCard from './AvaNodeCard'

const CalculatorForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  gap: 43px;
`

const FieldContainer = styled(Container)`
  width: 500px;
`

const BatteryContainer = styled(FieldContainer)`
  flex-direction: row;
  align-items: center;
  gap: 30px;
`

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const InputName = styled(StyledSubtitle)`
  margin-bottom: 12px;
`

const gpuOptions: GPUOption[] = [
  {
    gpu_model_name: 'Nvidia 4090 24G',
    gpu_rent_probability: 0.3,
    gpu_rent_cost_per_hour: 0.45,
  },
  {
    gpu_model_name: 'Nvidia RTX 5080 24G',
    gpu_rent_probability: 0.3,
    gpu_rent_cost_per_hour: 0.45,
  },
  {
    gpu_model_name: 'Nvidia RTX 5000 ADA',
    gpu_rent_probability: 0.3,
    gpu_rent_cost_per_hour: 1.0,
  },
]
export type GPUOption = {
  gpu_model_name: string
  gpu_rent_probability: number
  gpu_rent_cost_per_hour: number
}

const Calculator = () => {
  const { profile, refreshProfile } = useProfile()
  const [address, setAddress] = useState<string>(profile?.address ?? '')
  const [monthlyBill, setMonthlyBill] = useState<string>('')
  const [batteryCount, setBatteryCount] = useState<number>(1)
  const [gpuSettings, setGpuSettings] = useState({
    model: gpuOptions[0],
    cardCount: 1,
    rentProbability: gpuOptions[0].gpu_rent_probability * 100,
    rentCostPerHour: gpuOptions[0].gpu_rent_cost_per_hour,
  })
  const [profitsSources, setProfitsSources] = useState({
    sellingEnergy: true,
    blockchain: true,
    storageRent: true,
    gpuRent: true,
  })

  const { isLoaded } = useGoogleMapsLoader()

  useEffect(() => {
    document.title = 'Calculator'
    void refreshProfile()
  }, [refreshProfile])

  const handleAddressSelect = useCallback((value: string) => {
    setAddress(value)
  }, [])

  const handleMonthlyBillSelect = useCallback((value: string) => {
    setMonthlyBill(value)
  }, [])

  const handleGPUChange = useCallback((gpuModel: string) => {
    const selectedGpu = gpuOptions.find((gpu) => gpu.gpu_model_name === gpuModel)
    if (selectedGpu) {
      setGpuSettings((prevSettings) => ({
        ...prevSettings,
        model: selectedGpu,
        rentProbability: selectedGpu.gpu_rent_probability * 100,
        rentCostPerHour: selectedGpu.gpu_rent_cost_per_hour,
      }))
    }
  }, [])

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const formData = {
        gpu: {
          model: {
            name: gpuSettings.model.gpu_model_name,
            monthlyLoad: gpuSettings.rentProbability,
            hourlyRate: gpuSettings.rentCostPerHour,
          },
          cards: gpuSettings.cardCount,
        },
        address: profile?.address ?? address,
        monthlyBill,
        ratePerKw: 0.26,
        solar: true,
        battery: {
          capacity: 10,
          count: batteryCount,
        },
        profitSources: {
          ...profitsSources,
          electricBillSavings: true,
        },
      }

      console.log(formData)
    },
    [
      gpuSettings.model.gpu_model_name,
      gpuSettings.rentProbability,
      gpuSettings.rentCostPerHour,
      gpuSettings.cardCount,
      profile?.address,
      address,
      monthlyBill,
      batteryCount,
      profitsSources,
    ]
  )

  return (
    <CalculatorForm onSubmit={handleSubmit}>
      {isLoaded ? (
        <FieldContainer>
          <AddresInput onSelectPlace={handleAddressSelect} isCalculator />
        </FieldContainer>
      ) : (
        <Oval
          height="80"
          width="80"
          color="#506de7"
          secondaryColor="#0033ff"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      )}
      <GridWrapper>
        <FieldContainer>
          <AverageElectricBill onBillChange={handleMonthlyBillSelect} value={monthlyBill} isCalculator />
        </FieldContainer>
        <FieldContainer>
          <InputName>Rate per kW</InputName>
          <InputNumber value={0.26} symbol="$" symbolPosition={25} disabled />
        </FieldContainer>
      </GridWrapper>
      <FieldContainer>
        <ToggleOption text="Solar" disabled />
      </FieldContainer>
      <FieldContainer>
        <OptionTitle>Battery</OptionTitle>
        <BatteryContainer>
          <InputName>Number of Batteries:</InputName>
          <Counter
            initialValue={1}
            onCountChange={useCallback((count) => {
              setBatteryCount(count)
            }, [])}
          />
        </BatteryContainer>
        <InputName>Capacity: 10 kwh</InputName>
      </FieldContainer>
      <AvaNodeCard
        gpuOptions={gpuOptions}
        onGPUChange={handleGPUChange}
        gpuSettings={gpuSettings}
        setGpuSettings={setGpuSettings}
      />
      <ProfitSources
        rentCostPerHour={gpuSettings.rentCostPerHour}
        rentProbability={gpuSettings.rentProbability}
        profitsSources={profitsSources}
        setProfitsSources={setProfitsSources}
      />
      <EstimatedSystem />
      <FieldContainer>
        <Button $buttonType="primary" size="xl" text="Calculate" theme="default" type="submit" />
      </FieldContainer>
    </CalculatorForm>
  )
}

export default Calculator
